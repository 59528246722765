<template>
  <b-overlay :show="loading">
    <preview-modal @submit="submitAkun" />
    <b-row>
        <b-col cols="12">
          <setoran-summarize :summarize="givenSummarize" />
        </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-button v-if="isFinance" @click.prevent="showPreviewModal" :disabled="checkedSetoran.length < 1" :variant="checkedSetoran.length > 0 ? 'outline-primary' : 'outline-secondary'">
            Pilih Akun & Kas <span v-if="checkedSetoran.length > 0">({{ checkedSetoran.length }})</span>
          </b-button>
        </b-col>
        <b-col md="6" sm="12" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12" class="my-1">
          <b-form-group
            :label="$t('Filter')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">{{$t('Clear')}}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- data -->
        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #head(checkbox)>
              <b-form-checkbox v-model="allChecked"></b-form-checkbox>
            </template>
            <template #cell(checkbox)="{ item }">
              <b-form-checkbox v-if="item.metode != 4 || item.nominal > 0" v-model="checkedSetoran" :value="item"></b-form-checkbox>
              <feather-icon class="text-danger" icon="SlashIcon" v-else />
            </template>
            <template #cell(no)="{ index }">
              {{ index + 1 }}
            </template>
            <template #cell(tgl_pembayaran)="{ item }">
            {{humanDate(item.tgl_pembayaran)}}
          </template>
            <template #cell(sales)="{item}">
              <a href="#" v-if="item.sales">
                <strong>{{ item.sales.nama_lengkap }}</strong>
              </a>
              <i class="text-danger" v-else>Sales tidak ditemukan</i>
            </template>
            <template #cell(metode)="data">
              <b-badge :variant="imetode[1][data.value]">
                {{ imetode[0][data.value] }}
              </b-badge>
            </template>
            <template #cell(nominal)="{ item }">
              <strong v-if="item.metode_ket === 'Giro'"
                >Rp
                {{
                  formatRupiah(item.giro)
                }}</strong
              >
              <strong v-else
                >Rp
                {{
                  formatRupiah(item.nominal)
                }}</strong
              >
            </template>
            <template #cell(stocks)="{ item }">
              {{ getTotalStock(item.stocks) }}
            </template>
            <template #cell(jumlah_retur)="{ item }">
              <b-form-input type="number" v-model="item.jumlah_retur"></b-form-input>
            </template>
          </b-table>
        </b-col>
        <!-- / -->
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
import SetoranSummarize from './Summarize.vue'
  import PreviewModal from './PreviewModal.vue'
  import {
    BRow,
    BCol,
    BOverlay,
    BInputGroup,
    BInputGroupAppend,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    BCardText,
    BTable,
    BFormCheckbox,
    BTooltip,
    BPagination,
  } from "bootstrap-vue";
export default {
  components: {
    SetoranSummarize,
    PreviewModal,
    BOverlay,
    BCard,
    BInputGroupAppend,
    BFormCheckbox,
    BTable,
    BPagination,
    BTooltip,
    BFormCheckbox,
    BInputGroup,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  watch: {
    allChecked(val) {
      if (val) {
        this.checkedSetoran = [];
        this.items.map((barang) => {
          this.checkedSetoran.push(barang);
        });
      } else {
        this.checkedSetoran = [];
      }
    },
  },
  data: () => ({
    imetode: [
        {
          1: "Tunai",
          2: "Transfer",
          3: "Giro",
          4: "Nota Putih",
        },
        {
          1: "light-primary",
          2: "light-danger",
          3: "light-warning",
          4: "light-dark",
        },
      ],
    loading: false,
    allChecked: false,
    checkedSetoran: [],
    items: [],
    perPage: 10,
    pageOptions: [10, 30, 50],
    totalRows: 1,
    currentPage: 1,
    sortBy: "",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    fields: [
      { key: "no", label: "No" },
      { key: "tgl_pembayaran", label: "Tanggal", sortable: true },
      { key: "metode", label: "Metode", sortable: true },
      { key: "bank", label: "bank", sortable: true },
      { key: "nominal", label: "nominal", sortable: true },
      { key: "keterangan", label: "Keterangan", sortable: true },
    ],
    swalOptions: {
      title: "Setor Pembayaran ke Finance",
      text: `Anda yakin?`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
      customClass: {
        confirmButton: "btn btn-outline-success",
        cancelButton: "btn btn-danger ml-1",
      },
      buttonsStyling: false,
    },
  }),
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    givenSummarize() {
      return this.$store.getters['sudahsetor/getSummarize']
    },
    myDataOnly() {
      return this.$store.state.setoran.myDataOnly
    }
  },
  methods: {
    async submitAkun(payloads) {
      let i = 0
      this.loading = true
      this.$bvModal.hide('preview-modal')
      for(let i = 0; i < payloads.length; i++) {
        let payload = payloads[i]
        try {
          await this.$store.dispatch('sudahsetor/sendMoney', payload)
          if(i == payloads.length - 1) {
            this.displaySuccess({
              message: 'Setoran sudah diterima'
            })
            this.loading = false
            setTimeout(() => window.location.reload(), 1000)
          }
        }
        catch(e) {
          this.loading = false
          this.$bvModal.show('preview-modal')
          this.displayError(e)
          return false
        }
      }
    },
    async getAkun() {
        const akuns = await this.$store.dispatch('akun/getData')
        const debitAkun = akuns.filter(item => [this.JENIS_AKUN.debit, this.JENIS_AKUN.debit_kredit].includes(item.jenis))
        const akunOptions = debitAkun.map(akun => ({text: `${akun.nama} -> Rp ${this.formatRupiah(akun.saldo)}`, value: akun.id, saldo: akun.saldo, kategori: akun.kategori}))
        
        this.$store.commit('sudahsetor/SET_DATA_AKUN', akunOptions)
    },
    async getKas() {
        const listKas = await this.$store.dispatch('kas/getData')
        const kasOptions = listKas.map(kas => ({text: `${kas.nama} -> Rp ${this.formatRupiah(kas.saldo)}`, saldo: kas.saldo, value: kas.id}))
        this.$store.commit('sudahsetor/SET_DATA_KAS', kasOptions)
    },
    showPreviewModal() {
      // set current checked 
      this.$store.commit('sudahsetor/SET_CHECKED_SETORAN', this.checkedSetoran)

      // open Modal preview
      this.$bvModal.show('preview-modal')
    },
    async submit(payload) {
      this.loading = true;
      await this.$store.dispatch("penjualan/saveSetoran", payload);
      this.loading = false;
      this.$emit("submitRetur", true)
    },
    returnBarang() {
      this.$swal(this.swalOptions).then((res) => {
        if (res.value) {
          this.$bvModal.show("retur-modal");
        }
      });
    },
    getTotalStock(stocks) {
      if (!stocks || stocks.length < 1) {
        return 0;
      }
      return stocks.reduce((total, item) => (total += item.jumlah), 0);
    },
    async getData() {

      let params = this.isSales
        ? { id_sales: this.user.karyawan.id, order: "desc", disetor: 1 }
        : { order: "desc", disetor: 1 };

      this.loading = true;
      const payments = await this.$store.dispatch("sudahsetor/getData", params);
      payments.map(payment => {
        if(payment.diterima != 2) {
          this.items.push(payment)
        }
      })
      const summarize = {
        tunai: this.items.filter(payment => payment.metode == 1),
        transfer: this.items.filter(payment => payment.metode == 2),
        giro: this.items.filter(payment => payment.metode == 3),
        nota_putih: this.items.filter(payment => payment.metode == 4)
      }
      this.$store.commit('sudahsetor/SET_DATA_SUMMARIZE', summarize)
      this.loading = false;
      this.totalRows = this.items.length;
    },
    async getDataSetoran() {
      let params = this.isSales
        ? { id_sales: this.user.karyawan.id, order: "desc" }
        : { order: "desc" };

      // if (this.selectedStatus && this.selectedStatus != "all") {
      //   params.status = this.selectedStatus;
      // }
      this.loading = true;
      const ajuans = await this.$store.dispatch("penjualan/getDataSetoran", params);
      this.loading = false;
      this.items = ajuans;
      this.totalRows = ajuans.length;
    },
    async getBarang() {
      this.barangs = await this.$store.dispatch("barang/getDataV2");
      const barangIds = this.barangs.map((barang) => barang.id);
      this.getStockBarang(barangIds);
    },
    async getStockBarang(barangIds) {
      const params = {
        // barang_id: barangIds.join(','),
        // gudang_id: this.$route.params.id,
        gudang_id: this.myGudang.id,
      };

      const stocks = await this.$store.dispatch("penyimpanan/getData", params);
      this.barangs.map((barang) => {
        barang.stocks = [];
        const findStocks = stocks.filter(
          (stock) => stock.barang && stock.barang.id == barang.id
        );

        if (findStocks && findStocks.length > 0) {
          findStocks.forEach((stock) => {
            barang.stocks.push({
              id: stock.id,
              jumlah: stock.jumlah,
              gudang: stock.gudang,
              blok: stock.blok,
              rak: stock.rak,
              laci: stock.laci,
            });
          });
        }

        barang.jumlah_retur =
          barang.stocks.length > 0 ? this.getTotalStock(barang.stocks) : 0;
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDataGudang1() {
      await this.$store
        .dispatch("gudang/getData", { jenis: 1 })
        .then(() => {
          let gudang = JSON.parse(JSON.stringify(this.$store.state.gudang.datas));
          gudang.map((item) => {
            item.value = item.id;
            item.text = item.nama_gudang;
          });

          this.id_gudang = gudang;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    setField() {
      if(!this.isSales) {
        this.fields = [
          { key: "checkbox", label: "No" },
          { key: "no", label: "No" },
          { key: "sales", label: "Sales" },
          { key: "tgl_pembayaran", label: "Tanggal", sortable: true },
          { key: "metode", label: "Metode", sortable: true },
          { key: "bank", label: "bank", sortable: true },
          { key: "nominal", label: "nominal", sortable: true },
          { key: "keterangan", label: "Keterangan", sortable: true }
        ]
      }
    }
  },
  created() {
    this.setField()
    this.getData()
    this.getAkun()
    this.getKas()
  },
};
</script>
